
import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import WizardButtonGroup from '@/components/button/WizardButtonGroup.vue';
import TextButton from '@/components/button/TextButton.vue';
import { ActiviteitClass } from '@/domain/interface/activiteit/ActiviteitClass';
import DeleteDialog from '@/components/dialog/DeleteDialog.vue';
import { DeleteDialogObject } from '@/domain/interface/DeleteDialogObject';

@Component({
  components: {
    DeleteDialog,
    TextButton,
    WizardButtonGroup,
  },
})
export default class ActiviteitDeleteDialog extends Vue {
  @Prop(Boolean) value!: boolean;
  @Prop(Object) readonly activiteit!: ActiviteitClass;
  @Provide() deleteObject!: DeleteDialogObject;

  created() {
    this.deleteObject = new DeleteDialogObject();
    this.deleteObject.naam = this.activiteit.meldingsnummer;
    this.deleteObject.links = this.activiteit.links;
  }

  verwijder() {
    this.$emit('on-deleted');
  }

  annuleer() {
    this.$emit('close');
  }
}
