
import { Component, Vue } from 'vue-property-decorator';
import { envIsLeeromgeving } from '@/main';

@Component
export default class Leeromgeving extends Vue {
  envIsLeeromgeving = false;
  created() {
    this.envIsLeeromgeving = envIsLeeromgeving;
  }
}
