import HttpService from '@/services/HttpService';
import { BevestigingActiviteitRequestDto } from '@/domain/interface/activiteit/bevestigmelding/BevestigingActiviteitRequestDto';
import { ActiviteitDetailClass } from '@/domain/interface/activiteit/ActiviteitDetailClass';
import { Paginated } from '@/domain/interface/Paginated';
import { MeldingSearchParams } from '@/domain/interface/intern/MeldingSearchParams';

export default class ActiviteitService {
  private readonly PATH = '/bevestiging-melding/valideren-en-indienen';

  constructor(private http: HttpService) {}

  valideerMeerdereMeldingen(meldingen: BevestigingActiviteitRequestDto[]): Promise<void> {
    return this.http.post<void>(`${this.PATH}`, meldingen);
  }

  activiteitenOphalen(url: string, searchParams: MeldingSearchParams): Promise<Paginated<ActiviteitDetailClass>> {
    return this.http.get<Paginated<ActiviteitDetailClass>>(url, {
      params: { ...searchParams },
    });
  }

  getAantalTeValiderenActiviteiten(url: string): Promise<number> {
    return this.http.get<number>(url);
  }
}
