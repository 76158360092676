
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconButton from '@/components/button/IconButton.vue';
import { namespace } from 'vuex-class';
import { ErrorInterface } from '@/store/modules/errors';

const Errors = namespace('Errors');

@Component({
  components: { IconButton },
})
export default class Alert extends Vue {
  created() {
    setTimeout(() => {
      this.updateErrors([]);
    }, 5000);
  }

  @Prop() readonly errors!: [];

  @Errors.Action
  public updateErrors!: (errors: ErrorInterface[]) => void;
}
