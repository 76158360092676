
import { Component, Vue } from 'vue-property-decorator';
import VlaanderenLogo from '@/components/layout/VlaanderenLogo.vue';
import FooterLink from '@/components/links/FooterLink.vue';
import Separator from '@/components/Separator.vue';

@Component({
  components: { FooterLink, VlaanderenLogo, Separator },
})
export default class Footer extends Vue {
  BASE_URL = process.env.BASE_URL;
}
