
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MenuItemInterface } from '@/domain/interface/intern/MenuItemInterface';
import MenuButton from '@/components/menu/MenuButton.vue';

@Component({ components: { MenuButton } })
export default class InfoCard extends Vue {
  @Prop() readonly datumIngediend: string | undefined;
  @Prop() readonly items: object[] | undefined;
  @Prop(Array) readonly menuItems!: MenuItemInterface[];

  get datumIngediendText() {
    if (this.datumIngediend) {
      return `Ingediend op ${this.$options.filters?.formatDate_ddmmyyyy_slash(
        this.datumIngediend
      )}`;
    }
    return '';
  }

  clickItem(item: object) {
    this.$emit('click-item', item);
  }
}
