
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ProgressCircleRow extends Vue {
  @Prop({ default: 'Aan het laden...' }) readonly text!: string;
  @Prop({ default: '70' }) readonly size!: string;
  @Prop({ default: '7' }) readonly width!: string;
  @Prop({ default: 'primary' }) readonly color!: string;
}
