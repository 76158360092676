
import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';

const Gebruiker = namespace('Gebruiker');

@Component
export default class Avatar extends Vue {
  afmelden() {
    this.$router.push('/afmelden');
  }

  @Gebruiker.Getter
  public volledigeNaam!: string;

  @Gebruiker.Getter
  public rrn!: string;

  @Gebruiker.Getter
  public registratierollen!: string;

  @Gebruiker.Action
  public updateGebruiker!: () => Promise<void>;

  @Gebruiker.Getter
  public erkenningen!: string;

  @Gebruiker.Getter
  public initialen!: string;
}
