
import { Component, Prop, Vue } from 'vue-property-decorator';
import PrimaryButton from '@/components/button/PrimaryButton.vue';
import SecondaryButton from '@/components/button/SecondaryButton.vue';

@Component({
  components: { SecondaryButton, PrimaryButton },
})
export default class WizardButtonGroup extends Vue {
  @Prop({ default: 'Volgende' }) readonly nextText!: string;
  @Prop({ default: 'Terug naar overzicht' }) readonly homeText!: string;
  @Prop({ default: false }) readonly nextDisabled!: boolean;
  @Prop({ default: true }) readonly nextVisible!: boolean;
  @Prop({ default: 'Annuleren' }) readonly backText!: string;
  @Prop({ default: false }) readonly backDisabled!: boolean;
  @Prop({ default: true }) readonly backVisible!: boolean;
  @Prop({ default: false }) readonly homeDisabled!: boolean;
  @Prop({ default: false }) readonly homeVisible!: boolean;
  @Prop({ default: '/' }) readonly homeLocation!: string;
  @Prop(String) readonly name?: string;

  next(): void {
    this.$emit('next');
  }

  back(): void {
    this.$emit('back');
  }

  home(): void {
    this.$router.push({ name: this.homeLocation });
  }

  get nextButtonId() {
    return this.name ? 'next-button-' + this.name : 'next-button';
  }

  get backButtonId() {
    return this.name ? 'back-button-' + this.name : 'back-button';
  }

  get homeButtonId() {
    return this.name ? 'home-button-' + this.name : 'home-button';
  }

  get breakpoint() {
    return this.$vuetify.breakpoint.name;
  }
}
