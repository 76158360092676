
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import IconButton from '@/components/button/IconButton.vue';
import NotificationService from '@/services/NotificationService';

@Component({
  components: { IconButton },
})
export default class WarningAlert extends Vue {
  @Inject('notificationService') notificationService!: NotificationService;
  private readonly timeout = 5000;
  private keepAlertOpenActive = false;
  private delayForClosurePassed = false;

  created() {
    setTimeout(() => {
      if (!this.keepAlertOpenActive) {
        this.notificationService.resetWarnings();
      }
      this.delayForClosurePassed = true;
    }, this.timeout);
  }

  keepAlertOpen(): void {
    this.keepAlertOpenActive = true;
  }

  canCloseAlert(): void {
    if (this.delayForClosurePassed) {
      this.notificationService.resetWarnings();
    } else {
      this.keepAlertOpenActive = false;
    }
  }

  @Prop() readonly warnings!: [];
}
