
import { Component, Provide, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import VlaanderenLogo from '@/components/layout/VlaanderenLogo.vue';
import AuthorizationService from '@/services/AuthorizationService';
import { envIsLeeromgeving } from '@/main';
import Avatar from '@/components/avatar/Avatar.vue';
import Leeromgeving from '@/components/title/Leeromgeving.vue';

const auth = new AuthorizationService();

const HeaderStore = namespace('Header');
const Gebruiker = namespace('Gebruiker');

@Component({
  components: { Leeromgeving, Avatar, VlaanderenLogo },
})
export default class Header extends Vue {
  @Provide() isLoggedIn = false;
  @Provide() loading = false;

  envIsLeeromgeving = false;

  created() {
    this.envIsLeeromgeving = envIsLeeromgeving;
    if (process.env.NODE_ENV === 'development') {
      this.logInAndUpdateGebruiker();
    } else {
      auth.isLoggedIn().then(() => this.logInAndUpdateGebruiker());
    }
  }

  logInAndUpdateGebruiker() {
    this.loading = true;
    this.isLoggedIn = true;
    this.updateGebruiker().finally(() => (this.loading = false));
  }

  @Gebruiker.Action
  public updateGebruiker!: () => Promise<void>;

  @HeaderStore.Getter
  public getTitle!: () => string;
}
