
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconButton from '@/components/button/IconButton.vue';
import { namespace } from 'vuex-class';
import { SuccesMessage } from '@/store/modules/successAlertMessages';

const SuccessAlertMessages = namespace('SuccessAlertMessages');

@Component({
  components: { IconButton },
})
export default class SuccessAlert extends Vue {
  private readonly timeout = 3000;

  created() {
    setTimeout(() => {
      this.updateMessages([]);
    }, this.timeout);
  }

  @Prop() readonly messages!: [];

  @SuccessAlertMessages.Action
  public updateMessages!: (messages: SuccesMessage[]) => void;
}
